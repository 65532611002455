<template>
  <layout-vertical>

    <router-view />

    <!-- <app-customizer
      v-if="showCustomizer"
      slot="customizer"
    /> -->

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
// import AppCustomizer from '@core/layouts/components/app-customizer/AppCustomizer.vue'
import { $themeConfig } from '@themeConfig'
import { mapGetters } from 'vuex'


export default {
  components: {
    // AppCustomizer,
    LayoutVertical
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.handleChatWidget()
    }
  },
  data() {
    return {
      showCustomizer: $themeConfig.layout.customizer
    }
  },
  mounted() {
    if (this.mySession.id) this.handleChatWidget()
  },
  methods: {
    handleChatWidget() {
      const user = this.$session.get('cas_user')
      const iframe = document.getElementById('chat-iframe')
      if (iframe) {
        user?.user_access?.show_chat ? this.showChatWidget(iframe, user) : this.hideChatWidget(iframe)
      }
    },
    showChatWidget (iframe, user) {
      let computedStylesImg = null
      iframe.style.display = 'block'
      const widget = iframe.contentDocument.querySelector('app-widget-root')
      if (widget) {
        widget.init()
        widget.setCustomContactId(user.email)
        setTimeout(() => {
          const btnOpen = iframe.contentDocument.querySelector('.chattigo-widget-trigger__logo')
          const btnClose = iframe.contentDocument.querySelector('.chattigo-widget__close-icon')
          const btnDismiss = iframe.contentDocument.querySelector('.chattigo-icon-arrow-down')
          const conditionWelcome = iframe.contentDocument.querySelector('.chattigo-widget--welcome')
          if (!!btnOpen) {
            computedStylesImg = iframe.contentWindow.getComputedStyle(btnOpen)
          }
          iframe.style.opacity = 1
          const conditionButton = !!btnOpen && (!btnClose && !btnDismiss)
          iframe.style.width = conditionButton ? computedStylesImg?.width : '500px'
          iframe.style.height = conditionButton ? computedStylesImg?.height : '100%'
          iframe.style.maxHeight = conditionButton || !!conditionWelcome ? '380px' : '600px'
          iframe.style.maxWidth = '400px'
        }, 2000)
        const conditionsCss = () => {
          const conditionWelcome = iframe.contentDocument.querySelector('.chattigo-widget--welcome')
          const conditionEnding = iframe.contentDocument.querySelector('.chattigo-widget--ending')
          if (!!conditionEnding) {
            iframe.style.maxHeight = '410px'
          } else if (!!conditionWelcome) {
            iframe.style.maxHeight = '380px'
          } else {
            iframe.style.maxHeight = '600px'
          }
        }
        iframe.contentDocument.addEventListener('click', function(e) {
          if (e.target.matches('.chattigo-widget-trigger__logo')) {
            iframe.style.width = '500px'
            iframe.style.height = '100%'
          }
          const buttons = [
            '.chattigo-icon-close',
            '.chattigo-widget__close-icon',
            '.chattigo-icon-arrow-down'
          ]
          if (buttons.some(button => e.target.matches(button))) {
            iframe.style.width = computedStylesImg?.width || '80px'
            iframe.style.height = computedStylesImg?.height || '80px'
          }
        })
        const observerOptions = {
          childList: true,
          subtree: true
        }
        const observer = new MutationObserver(conditionsCss)
        observer.observe(widget, observerOptions)
      }
    },
    hideChatWidget (iframe) {
      iframe.style.display = 'none'
      const widget = document.querySelector('app-widget-root')
      if (widget) widget.logout()
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@core/scss/base/bootstrap-extended/include';
</style>
