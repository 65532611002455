<template>
  <div
    class="vertical-layout h-100"
    :class="[layoutClasses]"
    :data-col="isNavMenuHidden ? '1-column' : null"
  >
    <!-- Navbar -->
    <b-navbar
      :toggleable="false"
      :variant="navbarBackgroundColor"
      class="header-navbar navbar navbar-shadow align-items-center"
      :class="[navbarTypeClass]"
    >
      <slot
        name="navbar"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :navbarBackgroundColor="navbarBackgroundColor"
        :navbarTypeClass="[...navbarTypeClass, 'header-navbar navbar navbar-shadow align-items-center']"
      >
        <app-navbar-vertical-layout :toggle-vertical-menu-active="toggleVerticalMenuActive" :tourActive="tourActive"/>
      </slot>
    </b-navbar>
    <!--/ Navbar -->

    <!-- Vertical Nav Menu -->
    <vertical-nav-menu
      v-if="!isNavMenuHidden"
      :tourActive="tourActive"
      :is-vertical-menu-active="isVerticalMenuActive"
      :toggle-vertical-menu-active="toggleVerticalMenuActive"
    >
      <template #header="slotProps">
        <slot
          name="vertical-menu-header"
          v-bind="slotProps"
        />
      </template>
    </vertical-nav-menu>
    <!-- /Vertical Nav Menu -->

    <!-- Vertical Nav Menu Overlay -->
    <div
      class="sidenav-overlay"
      :class="overlayClasses"
      @click="isVerticalMenuActive = false"
    />
    <!-- /Vertical Nav Menu Overlay -->

    <!-- Content -->

    <!-- CONTENT TYPE: Left -->
    <transition
      :name="routerTransition"
      mode="out-in"
    >
      <component
        :is="layoutContentRenderer"
        :key="layoutContentRenderer === 'layout-content-renderer-left' ? $route.meta.navActiveLink || $route.name : null"
      >
        <template
          v-for="(index, name) in $scopedSlots"
          v-slot:[name]="data"
        >
          <slot
            :name="name"
            v-bind="data"
          />
        </template>
      </component>
    </transition>
    <!--/ Content -->

    <!-- Footer -->
    <!-- <footer
      class="footer footer-light"
      :class="[footerTypeClass]"
    >
      <slot name="footer">
        <app-footer />
      </slot>
    </footer> -->
    <!-- /Footer -->

    <slot name="customizer" />
    <v-tour 
      v-if="tourActive"
      id="tourFreemium" 
      name="tourFreemium" 
      class="tour-overlay"
      :steps="steps" 
      :options="myOptions" 
      :callbacks="myCallbacks">
    </v-tour>
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import AppNavbarVerticalLayout from '@core/layouts/components/app-navbar/AppNavbarVerticalLayout.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import useAppConfig from '@core/app-config/useAppConfig'
import { BNavbar } from 'bootstrap-vue'
import LayoutContentRendererDefault from '@core/layouts/components/layout-content-renderer/LayoutContentRendererDefault.vue'
import LayoutContentRendererLeft from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeft.vue'
import LayoutContentRendererLeftDetached from '@core/layouts/components/layout-content-renderer/LayoutContentRendererLeftDetached.vue'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import useVerticalLayout from './useVerticalLayout'
import mixinVerticalLayout from './mixinVerticalLayout'
import { mapGetters } from 'vuex'

export default {
  components: {
    // AppBreadcrumb,
    AppNavbarVerticalLayout,
    AppFooter,
    VerticalNavMenu,
    BNavbar,
    LayoutContentRendererLeftDetached,
    LayoutContentRendererLeft,
    LayoutContentRendererDefault
  },
  mixins: [mixinVerticalLayout],
  computed: {
    layoutContentRenderer() {
      const rendererType = this.$route.meta.contentRenderer
      if (rendererType === 'sidebar-left') return 'layout-content-renderer-left'
      if (rendererType === 'sidebar-left-detached') return 'layout-content-renderer-left-detached'
      return 'layout-content-renderer-default'
    },
    ...mapGetters({
      mySession: 'getSession'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.isUserFreemiun()
    }
  },
  data () {
    return {
      steps: [],
      myOptions: {
        labels: {
          buttonSkip: 'X',
          buttonPrevious: 'Anterior',
          buttonNext: 'Siguiente',
          buttonStop: 'Finalizar'
        },
        highlight: false,
        enableScrolling: false
      },
      myCallbacks: {
        onStop: this.finishTour
      },
      tourActive: false
    }
  },
  mounted () {
    if (this.mySession.id) this.isUserFreemiun()
  },
  methods: {
    isUserFreemiun () {
      if (this.mySession?.user_access?.company_category_id && this.mySession?.user_access?.company_category_id === 2 && (!this.mySession.firstLoginToFreemium && !this.$session.get('cas_user').firstLoginToFreemium)) { 
        this.configTour()
      }
    },
    configTour () {
      this.steps = [
        {
          target: '.step-tour-freemium',
          header: {
            title: '<strong>¡Bienvenido a tu cuenta Freemium!</strong>'
          },
          content: 'Encuentra métricas de tus envíos al mes en la sección Dashboard.',
          params: { 
            placement: 'left'
          }
        },
        {
          target: '.step-tour-freemium-2',
          header: {
            title: '<strong>Marketcenter - Canales de venta</strong>'
          },
          content: 'Encuentra los distintos marketplaces con los que te puedes conectar.',
          params: { 
            placement: 'left'
          }
        },
        {
          target: '.step-tour-freemium-3',
          header: {
            title: '<strong>Marketcenter - Envíos</strong>'
          },
          content: 'Gestiona tus envíos y encuentra información relevante como su estado.',
          params: { 
            placement: 'left'
          }
        },
        {
          target: '.step-tour-freemium-4',
          header: {
            title: '<strong>Empresa</strong>'
          },
          content: 'En esta sección podrás gestionar la información de tu cuenta.',
          params: { 
            placement: 'left'
          }
        }
      ]
      this.$nextTick(() => {
        this.tourActive = true
        setTimeout(() => {
          this.$tours['tourFreemium'].start()
        }, 200)
      }) 
    },
    finishTour () {
      this.tourActive = false
      this.$root.$data.accountsCollection.doc(this.mySession.id).update({firstLoginToFreemium: true})
      const user = this.$session.get('cas_user')
      user.firstLoginToFreemium = true
      this.$session.set('cas_user', user)
    }
  },
  setup() {
    const {
      routerTransition, navbarBackgroundColor, navbarType, footerType, isNavMenuHidden
    } = useAppConfig()

    const {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      layoutClasses,
      overlayClasses,
      resizeHandler,
      navbarTypeClass,
      footerTypeClass
    } = useVerticalLayout(navbarType, footerType)

    // Resize handler
    resizeHandler()
    window.addEventListener('resize', resizeHandler)
    onUnmounted(() => {
      window.removeEventListener('resize', resizeHandler)
    })

    return {
      isVerticalMenuActive,
      toggleVerticalMenuActive,
      isVerticalMenuCollapsed,
      overlayClasses,
      layoutClasses,
      navbarTypeClass,
      footerTypeClass,

      // App Config
      routerTransition,
      navbarBackgroundColor,
      isNavMenuHidden
    }
  }
}
</script>

<style lang="scss">
@import "~@core/scss/base/themes/bordered-layout.scss";
#tourFreemium .v-step {
    background-color: #fff !important;
    color: #000 !important;
    padding: 1rem;
    max-width: 366.62px;
}
#tourFreemium .v-step .v-step__content {
    margin: 0 0 0.8rem 0;
    font-size: 0.875rem;
    font-size: 14px;
}
#tourFreemium .v-step__header {
    margin: 0 0 0.8rem;
    padding: 0;
    font-size: 16px;
}
#tourFreemium .v-step .v-step__arrow {
    border-color: #fff;
}
#tourFreemium .v-step[x-placement^=right] .v-step__arrow {
    border-left-color: transparent !important;
}
#tourFreemium .v-step[x-placement^=right] .v-step__arrow {
    border-top-color: transparent !important;
    border-bottom-color: transparent !important;
}
#tourFreemium .v-step__buttons {
  display: grid;
  grid-template-columns: 25% 50% 25%;
}
#tourFreemium .v-step__button-next, #tourFreemium .v-step__button-stop {
  grid-column: 3;
}
#tourFreemium .v-step__button-skip{
  background: transparent !important;
  color: #00000080;
  position: absolute;
  right: 8px;
  top: 5px;
  border: none !important;
  font-size: 18px !important;
}
#tourFreemium .v-step__button-previous {
  background: transparent !important;
  color: #1967D2;
  border: none !important;
  padding: 0 !important;
  text-align: justify;
}
#tourFreemium .v-step__button {
    background: #1967D2;
    border-radius: 6px;
    margin: 0;
    padding: 0.5rem;
    height: initial;
    font-size: 14px;
}
#tourFreemium .v-step__button-skip:hover {
    color: #00000080 !important;
}
#tourFreemium .v-step__button:hover {
    color: #fff;
}
#tourFreemium .v-step__button-previous:hover {
    color: #1967D2 !important;
}
.tour-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  pointer-events: auto;
}
@media (max-width: 768px) {
  #tourFreemium .v-step {
    position: absolute;
    will-change: initial;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    margin: 0 !important;
    max-width: clamp(320px, 90%, 360px);
    padding: 1rem 1.5rem;
  }
  #tourFreemium .v-step .v-step__arrow {
    border: none !important;
  }
  #tourFreemium .v-step__header {
    margin: 2rem 0 1.5rem;
    text-align: center;
  }
  #tourFreemium .v-step .v-step__content {
    margin: 2rem 1rem;
    text-align: center;
  }
  #tourFreemium .v-step__buttons {
    grid-template-columns: 30% 40% 30%;
  }
  #tourFreemium .v-step__button-previous {
    text-align: center;
  }
  #tourFreemium .v-step__button-skip {
    top: 8px;
    right: 6px;
  }
}
</style>
